import ErrorNotFoundPage from './ErrorNotFoundPage';
import ContactUs from './ContactUs';
import InfoPage from './InfoPage';
import { Article } from './Article';
import { LandingPage } from './LandingPage';
import { SimplePage } from './SimplePage';
export {
  Article,
  ErrorNotFoundPage,
  ContactUs,
  InfoPage,
  LandingPage,
  SimplePage,
};
